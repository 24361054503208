import React from "react"
import { StaticQuery, graphql } from "gatsby"
import "../styles/components/_titleHeader.scss"

export default ({ title }) => (
  <StaticQuery
    query={graphql`
      query TitleHeaderQuery {
        allContentfulGeneral {
          nodes {
            redUnderline {
              file {
                url
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="title-header">
        <h1>{title}</h1>
        <div
          className="underline"
          style={{
            backgroundImage: `url(${data.allContentfulGeneral.nodes[0].redUnderline.file.url})`,
          }}
        ></div>
      </div>
    )}
  />
)
