import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"
import { graphql } from "gatsby"
import TitleHeader from "../components/titleHeader"
import BodyText from "../components/bodyText"
import Meta from "../components/meta"

import "../styles/templates/_legalPage.scss"

const LegalPageTemplate = ({ data, location }) => {
  return (
    <div className="legal-page">
      <Meta
        title={data.legalPage.seo.title}
        description={data.legalPage.seo.description.description}
        image={data.legalPage.seo.socialImage.file.url}
        url={location.href}
        imgAlt={data.legalPage.seo.socialImage.description}
      />
      <Header />
      <TitleHeader title={data.legalPage.title} />
      <BodyText
        text={data.legalPage.bodyText.json}
        className={data.legalPage.cssClasses?.bodyTextClass}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query LegalPageDetailsQuery($slug: String!) {
    legalPage: contentfulLegalPage(slug: { eq: $slug }) {
      bodyText {
        json
      }
      title
      seo {
        title
        description {
          description
        }
        socialImage {
          description
          file {
            url
          }
        }
      }
      cssClasses {
        bodyTextClass
      }
    }
  }
`

export default LegalPageTemplate
